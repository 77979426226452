<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <div class="enterprises-page">
    <b-card ref="refreshCard" class="" title="View Bills">
      <b-card-text>This page shows a list of all enterprise bills</b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <section>
        <b-input-group-append
          class="row d-flex align-items-center justify-content-between p-2"
        >
          <div class="col-6">
            <label>Enterprise</label>
            <enterprise-search-select
              :selected="selectedEnterprise"
              @change="updateSelectedEnterprise"
            />
          </div>
          <b-button class="mr-1" @click="clearFilters"> Clear Filters</b-button>
        </b-input-group-append>
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="bills"
          :per-page="0"
          class="data-table"
          hover
        >
          <template #cell(enterprise)="data">
            <router-link
              :to="{
                name: 'enterprise-view',
                params: { uid: data.item.enterprise.uid },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.enterprise.display_name }}
            </router-link>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTime }}
          </template>

          <template #cell(amount)="data">
            {{ data.item.amount | intToCurrency }}
          </template>

          <template #cell(user)="data">
            <router-link
              :to="{ name: 'user-view', params: { uid: data.item.user.uid } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.name }}
            </router-link>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              class="m-0"
              no-caret
              right
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button
                class="full-nested-btn"
                @click="viewBillPage(data.item.id)"
              >
                View Bill
              </b-dropdown-item-button>
              <b-dropdown-item-button
                class="full-nested-btn"
                @click="showBillModal(data.item, 'modal-delete-bill')"
              >
                Delete Bill
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="total_count"
              class="my-0"
              @change="getMoreBills"
            />
          </b-col>
          <b-col v-if="total_count === 0" class="ml-2 mt-1">
            <p>No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-bill" hide-footer title="Delete Bill">
      <delete-modal
        v-if="selectedBill"
        :subtitle="selectedBill.enterprise_display_name"
        title="Are you sure you wish to delete this bill?"
        @close="closeModals()"
        @delete="deleteBill(selectedBill)"
      ></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import BillService from "@/services/BillService";
import helperService from "@/services/HelperService";
import DeleteModal from "../../components/modals/DeleteModal.vue";
import { BButton } from "bootstrap-vue";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "ViewBills",
  components: {
    EnterpriseSearchSelect,
    BButton,
    DeleteModal,
  },
  data() {
    return {
      headers: [
        {
          key: "enterprise",
          label: "Enterprise",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "user",
          label: "Created By",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      bills: [],
      selectedEnterprise: null,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        enterprise_uid: "",
      },
      total_count: 0,
      selectedBill: {},
    };
  },
  mounted() {
    this.getBills();
  },
  methods: {
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        enterprise_uid: "",
      };
      this.selectedEnterprise = null;

      this.getBills();
    },
    getMoreBills(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getBills();
    },
    async getBills() {
      try {
        this.$refs.refreshCard.showLoading = true;
        const res = await BillService.getBills(this.filters);
        this.bills = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get bills, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        setTimeout(() => {
          this.$refs.refreshCard.showLoading = false;
        }, 100);
      }
    },
    async deleteBill() {
      try {
        await BillService.deleteBill(this.selectedBill.id);
        helperService.showNotfySuccess(
          this.$toast,
          "Bill successfully Deleted"
        );

        this.closeModals();
        this.selectedBill = {};
        await this.getBills();
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete bill, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    showBillModal(bill, modalName) {
      this.setSelectedBill(bill);
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide("modal-delete-bill");
    },
    setSelectedBill(bill) {
      this.selectedBill = bill;
    },
    viewBillPage(billId) {
      this.$router.push({ name: "bill-details", params: { id: billId } });
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.filters.enterprise_uid = ""
      } else {
        this.filters.enterprise_uid = this.selectedEnterprise.uid;
      }
      this.getMoreBills(1)
    }
  },
};
</script>
